.hv70 {
  height: 70vh;
}

.w25 {
  width: 25%;
}

.date {
  display: flex;
  width: 100%;
  overflow: hidden;
  border-width: 1px;
  height: 39px;
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
  --tw-border-opacity: 1;
  border-color: rgba(85, 85, 85, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(21, 21, 21, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(170, 170, 170, var(--tw-text-opacity));
}