.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  height: 100%;
  box-sizing: border-box;
  padding: 50px 10px;
}

.text {
  margin-bottom: 0;
  color: #fff;
  font-size: 20px;
  text-align: center;
}
