.container {
  padding: 15px 7px;
  border-top: 3px solid #151516;
}

.container:first-of-type {
  margin-top: 40px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-size: 18px;
  color: #fff;
  font-weight: 500;
}

.container.collapsible .header {
  cursor: pointer;
}

.container.collapsible .content {
  overflow: hidden;
  transition: max-height 0.4s ease-in-out;
}

.padding {
  padding: 20px 0;
}

.toggle {
  width: 35px;
  transition: transform 0.2s ease-in-out;
}

.toggle.open {
  transform: rotateX(180deg);
}
