.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.content {
  width: 445px;
  max-width: 100%;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  background: #000;
  border-radius: 4px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.content > form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.button {
  margin-top: 20px;
  height: 44px;
}

.button > * {
  font-size: 13px !important;
}
.header {
  text-align: center;
  color: #fff;
  margin-bottom: 30px;
}

.title {
  font-size: 26px;
}

.margin {
  margin-top: 20px;
  margin-bottom: 30px;
}

.logo {
  max-width: 100%;
  width: 265px;
  margin: 10px auto 30px 0;
}

.header p {
  color: #777;
  margin: 14px 0 10px 0;
}

.link {
  margin-top: 32px !important;
}

@media (min-width: 1024px) {
  .content {
    padding: 30px;
  }
}
