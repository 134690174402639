.logo {
  height: 35px;
  width: auto;
  margin: 0 10px;
}

.logoWithText {
  height: 46px;
  width: auto;
  margin: 0 10px;
  cursor: pointer;
}

.tagline {
  height: 35px;
  width: auto;
}

.socialContainer {
  width: 137px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.headerContainer {
  background-color: hsl(55, 23.08%, 89.8%);
}