.deviceSwitch {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.deviceSwitch > * {
  flex-grow: 0;
}

.deviceIcon {
  font-size: 28px;
  margin-right: 5px;
}

.deviceOS {
  font-size: 24px;
  font-weight: bold;
}

.tableWrapper {
  max-height: 600px;
}

.referralCode {
  font-size: 14px;
  font-weight: normal;
}
