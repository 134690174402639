.listWrapper {
  height: 70vh;
}

.slider {
  width: 250px;
  height: 3px;
  box-sizing: border-box;
  border-radius: 999px;
  display: flex;
  align-items: center;
  margin: 25px 0;
}

.track {
  top: 0;
  bottom: 0;
  border-radius: 999px;
  background-color: #4D4D4D;
}


.thumb {
  height: 32px;
  line-height: 32px;
  width: 32px;
  font-size: 14px;
  text-align: center;
  background-color: rgba(247, 212, 67, var(--tw-text-opacity));
  color: #000;
  border-radius: 50%;
  cursor: grab;
}

.thumb,
.thumb:active,
.thumb:focus {
  border: none;
  outline: none;
}

/* .horizontal-slider {
  width: 100%;
  max-width: 500px;
  height: 100vh;
  margin: auto;
}

.example-thumb {
  cursor: pointer;
  position: absolute;
  z-index: 100;
  background: #ffffff;
  border: 5px solid #3774ff;
  border-radius: 100%;
  display: block;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
}

.example-thumb.active {
  background-color: grey;
}

.example-track {
  position: relative;
  background: #ddd;
}

.example-track.example-track-0 {
  background: #83a9ff;
}

.horizontal-slider .example-track {
  top: 20px;
  height: 4px;
}

.horizontal-slider .example-thumb {
  top: 12px;
  width: 10px;
  outline: none;
  height: 10px;
  line-height: 38px;
} */