.container {
  box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.3), 0 4px 8px 3px rgba(60, 64, 67, 0.15);
  max-width: 100%;
  min-height: 52px;
  padding: 8px 24px;
  box-sizing: border-box;
  border-radius: 4px;
  flex-wrap: nowrap;
  background-color: #202124;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.closeIcon {
  margin-left: 24px;
  width: 20px;
  cursor: pointer;
}
