.tableWrapper {
  max-height: 600px;
}

.couponHeader {
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  z-index: 999;
}

.couponLabel {
  white-space: nowrap;
  margin-right: 5px;
}

.red {
  color: red;
}