.label {
  font-size: 16px;
  margin-bottom: 5px;
}

.report {
  white-space: pre-line;
}

.uploadingUsers {
  transform: scale(0.8);
}
