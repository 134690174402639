.switchWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.label {
  max-width: 100%;
  margin: 0 0 5px 0;
  position: relative;
}
.userCount {
  font-size: 14px;
  font-weight: normal;
}
