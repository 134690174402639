.mainContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: hsl(55, 23.08%, 89.8%);
}

.bgContainer {
  width: 100%;
  height: 43vw;
  position: relative;
}

.bgImageContainer {
  width: 100%;
  height: 43vw;
  position: relative;
  clip-path: url(#section-divider-64ca6066f1f4880f7cb5fdda)
}

.bgImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: bottom;
}

.bgText {
  white-space: pre-wrap;
  transition-timing-function: ease;
  transition-duration: 0.6s;
  transition-delay: 0.16875s;
  font-size: 4vw;
  line-height: 4.5vw;
  color: white;
  position: absolute;
  height: 100%;
  z-index: 2;
  background-color: #00000055;
  top: 0;
  width: 100%;
  padding-top: 18vw;
  padding-left: 4vw;
}

.sectionDividerSvgStroke {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: visible;
  z-index: 3;
}

.sectionDividerStroke {
  stroke: #a73e26;
  stroke-width: 6px;
  stroke-dasharray: 0;
  stroke-linecap: square;
  fill: transparent;
}

.secondContainer {
  width: 100%;
  padding: 4vw 6vw 0px 6vw;
  display: flex;
  flex-direction: row;
}

.halfContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.promoImage {
  width: 100%;
  object-fit: contain;
  padding: 24px;
}

.answer {
  font-size: 1rem;
  color: black;
}

.renewalTitle {
  font-size: 1rem;
  color: black;
}

.renewal {
  font-size: 0.8rem;
  color: black;
}

.whiteBox {
  width: 100%;
  background-color: white;
  border-style: solid;
  border-radius: 8px;
  margin-top: 16px;
}

.logoImage {
  width: 100%;
  height: 11vw;
  object-fit: contain;
}

.footerContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 6vw;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: black;
  padding: 2vw 0px 2vw 0px;
  align-items: center;
}

.blackText {
  color: black;
}

.socialContainer {
  width: 137px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.mailingButtonContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 6vw;
}

.mailingButton {
  color: white;
  width: 190px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  background-color: black;
  border-radius: 8px;
  cursor: pointer;
}

.submitButton {
  color: white;
  width: 124px;
  height: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  background-color: #568ea2;
  border-radius: 4px;
  cursor: pointer;
  gap: 5px;
}

.mailingButton:hover {
  opacity: 0.8;
}

.input {
  background: rgba(255, 255, 255, .1);
  border: none;
  border-radius: 4px;
  padding-block: 7px;
  padding-inline: 7px;
  width: 100%;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  background-color: #f5f7f8;
  color: #202124;
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.03) inset;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.03) inset;
  height: 29px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.input:focus-visible {
  background-color: #f5f7f8;
  filter: brightness(95%);
  outline-color: #568ea2;
  outline-offset: 4px;
  transition: outline-offset 145ms cubic-bezier(.25, 0, .4, 1);
}

.formHorizontalContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.formHorizontalBoxContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.formHorizontalSpacer {
  flex: 0.1;
  display: flex
}

.storeHorizontalContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.storeIcons {
  display: flex;
  object-fit: contain;
  width: 40%;
}

.centerText {
  display: flex;
  justify-content: center;
  color: black;
}

@media only screen and (max-width: 600px) {

  .bgContainer {
    height: 57vw;
  }

  .bgImageContainer {
    height: 57vw;
  }

  .bgText {
    padding-top: 32vw;
  }

  .secondContainer {
    flex-direction: column;
  }
}