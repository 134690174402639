.container {
  color: #f7d443;
  text-transform: capitalize;
  display: block;
  font-size: 14px;
  width: 100%;
  max-width: 80px;
  margin-right: 6px;
}

.white {
  color: #fff;
}
@media (min-width: 1024px) {
  .container {
    font-size: 15px;
    max-width: 90px;
    margin-right: 10px;
  }
}
