.container {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  font-size: 10px;
  margin-bottom: 28px;
}

.container > *:first-child {
  height: 42px;
  display: flex;
  align-items: center;
}

.textarea {
  align-items: end;
}

.error {
  left: 0 !important;
  white-space: nowrap;
}

.inputWrapper {
  position: relative;
  flex-grow: 1;
}

.container.secondary {
  display: block;
  margin-bottom: 24px;
}

.container.secondary input,
.container.secondary select {
  border: none;
  padding-left: 2px;
  font-size: 16px;
  padding: 0 2px 0 14px;
  border-radius: 6px;
}
