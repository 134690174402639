.badgeWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
}
.badgeWrapper img {
  height: 50px;
  width: auto;
  margin: 10px 0;
  border: 1px solid #fff;
  border-radius: 2px;
}

@media (min-width: 1024px) {
  .badgeWrapper {
    flex-direction: row;
  }
}
