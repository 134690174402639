.cropContainer {
  z-index: 99999;
}

.cropContent {
  position: relative;
}

.flip {
  transform: scaleX(-1);
}

.label {
  max-width: 100%;
}
