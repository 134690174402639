.enter {
  opacity: 0.01;
}

.enterActive {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.exit {
  opacity: 1;
}

.exitActive {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.container {
  bottom: 0;
  right: 0;
  margin: 24px;
  position: fixed;
}

.container > * {
  margin-top: 0.5em;
}
